@import 'variables';

.container {
  display: flex;
  flex-direction: column;
}

@media (max-width: $desktopMinWidth) {
  .container{
    padding: 0px 15px;
  }
}
