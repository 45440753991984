@import '../../styles/variables';

.btn {
  margin-top: 24px;
  padding: 8px;
  background: transparent;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: $primary;
  cursor: pointer;
  border: 2px solid $primary-darkened;
  transition: all ease-in 0.3s;
}
