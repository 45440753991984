@import '../../../styles/variables';

.card {
  padding: 24px;
  width: 100%;
  max-width: 325px;
  height: 377px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 15px;
  margin: 10px 0;
}

.cardSample {
  background: #012935;

  .title {
	background: #FBBE68;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
  }
}

.cardSample:hover {
  border: 2px solid #FBBE68;
  padding: 22px;
  // background: transparent;

  .title {
	background: linear-gradient(0deg, #FBBE68, #FBBE68), linear-gradient(347.67deg, #FBBE68 17.93%, #FBBE68 82.07%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
  }

  .mobPriceWrapper {
	display: none;
  }
}

.cardBody {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.cardSelected {
  background: linear-gradient(347.67deg, #EAD06A 17.93%, #D98B4C 82.07%);

  .name, .price, .priceTokenName {
	color: #111B35;
  }

  .title {
	background: #111B35;
	background-clip: text;
	-webkit-background-clip: text;
  }

  .rate, .mobPriceWrapper {
	min-width: 35px;
	display: none;
	text-align: right;
  }

  .purchase {
	color: #111B35;
  }
}

.cardDisabled {
  .title {
	opacity: 0.8;
  }

  .purchase, .rate {
	display: none;
  }
}

.buttonCurrent {
  width: 100%;
  color: $orange;
  background: #111B35;
  padding: 10px 18px;
  opacity: 1 !important;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 45px;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.name {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
}

.titleWrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.purchase,
.rate {
  margin-top: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  color: rgba(255, 255, 255, 0.64);
}

.price,
.mobPrice {
  margin-top: auto;
}

.button {
  padding: 10px 18px;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: black;
  border: none;
  cursor: pointer;
  background: #FEAF3C;
  border-radius: 10px;
  margin-bottom: 45px;
  width: 100%;
}

.buttonWrapper {
  width: 100%;
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.usdtPrice {
  font-weight: 800;
  font-size: 10px;
  line-height: 12px;
  text-align: right;

  color: rgba(255, 255, 255, 0.64);
}

.priceTokenName {
  color: #5fedcf;
}

.mobPrice {
  display: none;
}

@media (min-width: $desktopMaxWidth) {
  .card {
	width: 25%;
  }
}

@media (max-width: $tabletMediumWidth) {
  .card {
	max-width: none;
	width: 100%;
	height: 100%;
	align-items: stretch;
  padding-bottom: 50px;
  }

  .purchase {
	margin-top: 24px;
  }
  .price {
	display: none;
  }
  .button {
	margin-top: auto;
  }

  .buttonWrapper {
	margin-top: auto;
	flex-direction: row-reverse;
  }

  .mobPrice {
	display: block;
  }

  .priceBr {
	display: none;
  }

  .titleDivider {
	display: none;
  }
}
