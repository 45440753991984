@import '@app/styles/_variables.scss';

.wrapper {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #013342;
  border-radius: 16px;
  max-width: max-content;
  margin-left: auto;
}

.title {
  font-size: 1.1rem;
  line-height: 20px;
}

.subtitle {
  font-size: 1rem;
}

.text {
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.btn {
  background: $orange_300;
  padding: 10px 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  border-radius: 4px;
}

@media (max-width: $xl) {
  .wrapper {
    margin-right: auto;
    margin-left: 0;
  }
}

@media (max-width: $lg) {
  .wrapper {
    max-width: 100%;
  }
}

@media (max-width: $tabletMediumWidth) {
  .wrapper {
    padding: 20px;
  }
  .text {
    margin-bottom: 10px;
  }
  .btn {
    font-size: 18px;
    line-height: 30px;
    border-radius: 10px;
    font-weight: 800;
  }
}
