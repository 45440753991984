@import '@app/styles/variables';
.banner {
  margin-top: 16px;
  width: 100%;
  position: relative;
  background: #012935;
  border-radius: 16px;
  margin-bottom: 36px;
  opacity: 0.9;
  &_data {
    display: flex;
    justify-content: space-between;
    padding: 11px 30px;
    &_description {
      width: 50%;
      font-family: Playfair, sans-serif;
    }
    &_media {
      width: 40%;
      &_iframe {
        height: 250px;
      }
    }
  }
}

.downloadInsuranceBanner {
  cursor: pointer;
}

.subTitle {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  color: $white;
  margin-top: 10px;
  margin-bottom: 52px;
  font-family: Playfair, sans-serif;
}

@media (max-width: $xl) {
  .subTitle {
    margin-bottom: 16px;
  }
}

.bannerSteps {
  display: flex;
  background-color: #013746;
  height: 100px;
  justify-content: space-around;
  align-items: center;
  border-radius: 0px 0px 16px 16px;
}

.bannerStep {
  display: flex;
  align-items: center;
  font-size: 17px;
}

.bannerStepTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  background: $blue;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.bannerIconWrapper{
  margin-right: 10px;
  height: 40px;
  width: 30px;
  display: flex;
  align-items: center;
}

.insurancePolicy {
  background: linear-gradient(92.1deg, #D98B4C 16.91%,#EAD06A 82.35%), #142341;
  border-radius: 15px;
  min-height: 119px;
  padding: 0px 50px 0px 50px;
  &_text {
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    color: black;
  }
  &_right {
    width: 169px;
  }
  &_left {
    width: 24px;
  }
  &_box {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.warning {
  &_text {
    font-weight: 400;
    font-size: 14px;
    color: #ffff00;
    line-height: 25px;
    font-family: Playfair, sans-serif;
  }
  display: none;
}

@media (max-width: $desktopMinWidth) {
  .banner {
    &_data {
      flex-direction: column;
      padding: 21px 20px;
      &_description {
        width: 100%;
      }
      &_media {
        width: 100%;
        padding: 30px 0px;
        &_iframe {
          height: 244px;
        }
      }
    }
  }
  .bannerSteps {
    flex-direction: column;
    height: 100%;
    min-height: 300px;
    align-items: start;
    padding-left: 40px;
    border-radius: 16px;
  }

  .title {
    font-size: 40px;
    margin-top: 20px;
  }
  .subTitle {
    font-size: 21px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .warning {
    display: block;
  }
  .bannerIconWrapper{
   margin-right: 30px;
  }
  .insurancePolicy {
    padding: 42px;
    align-items: start;
    &_text {
      font-weight: 800;
      font-size: 14px;
      line-height: 23px;
      color: $blue_900;
    }
  }
}

PACKAGES
.packages {
  display: flex;
  min-width: 210px;
  justify-content: space-between;
  gap: 15px;
}

.morePackages {
  padding: 24px;
  width: 100%;
  max-width: 325px;
  height: 377px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 15px;
  border: 2px dashed rgba(77, 171, 245, 0.12);
}

.morePackagesName {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
}

.morePackagesTitle {
  margin-top: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background: linear-gradient(0deg, #5fedcf, #5fedcf),
    linear-gradient(347.67deg, #4dabf5 17.93%, #5fedcf 82.07%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.moreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 132px;
  height: 32px;
  font-family: Playfair, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #5fedcf;
  border: 2px solid rgba(95, 237, 207, 0.24);
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent;
  cursor: pointer;
}
